const TrainingVariantContext = {
    trainingLabel: null,
    helprTrainingLabel: null,
    clientId: null,
    clientSecret: null,

    getTrainingLabel() {
        if (!this.trainingLabel) {
            this.trainingLabel = 'elektrisch-rijden'; // default

            if (/ev/.test(window.location.hostname)) {
                this.trainingLabel = 'elektrisch-rijden';
            } else if (/abcde/.test(window.location.hostname)) {
                this.trainingLabel = 'abcde';
            }
        }

        return this.trainingLabel;
    },

    getHelprTrainingLabel() {
        if (!this.helprTrainingLabel) {
            switch (this.getTrainingLabel()) {
                case 'elektrisch-rijden':
                    this.helprTrainingLabel = 'evrijden';
                    break;
                case 'abcde':
                    this.helprTrainingLabel = 'abcde';
                    break;
            }
        }

        return this.helprTrainingLabel;
    },

    getBrowserTitle() {
        switch (this.trainingLabel) {
            case 'elektrisch-rijden':
                return 'Elektrisch rijden | NVWA';
            case 'abcde':
                return 'abcde | NVWA';
        }
    },

    getClientId() {
        if (!this.clientId) {
            this._setClient();
        }

        return this.clientId;
    },

    getClientSecret () {
        if (!this.clientSecret) {
            this._setClient();
        }

        return this.clientSecret;
    },

    _setClient() {
        switch (this.getTrainingLabel()) {
            case 'elektrisch-rijden':
                this.clientId = '16_2ggfgpr6qf6sc4kg8csoccw8wgg44sg084kco4c084c88k8ssg';
                this.clientSecret = '3amcqh35w8sg8gcs4w4o4wck4s8kocgk0ks80ow4kokws88808';
                break;
            case 'abcde':
                this.clientId = '16_2ggfgpr6qf6sc4kg8csoccw8wgg44sg084kco4c084c88k8ssg';
                this.clientSecret = '3amcqh35w8sg8gcs4w4o4wck4s8kocgk0ks80ow4kokws88808';
                break;
        }
    }
};

export default TrainingVariantContext
