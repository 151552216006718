<template>
    <v-main>
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="11" md="10" lg="8" xl="4">
                    <v-card class="elevation-12">
                        <HeaderImage/>
                        <v-card-text v-if="chapter" class="px-8">
                            <v-row align="center" justify="start">
                                <h1 class="mb-2">{{chapter.index}}. {{chapter.title}}</h1>
                            </v-row>

                            <div class="slides">
                                <div class="slide" v-for="slide in chapter.slides" :key="slide.id">
                                    <router-link v-if="slide.isFinished || chapter.nextSlide === slide" :to="{ name: 'slide', params: { slideId: slide.id }}">
                                        <h3>{{slide.index}}. {{slide.name}}</h3>
                                    </router-link>
                                    <h3 v-else>{{slide.index}}. {{slide.name}}</h3>

                                    <div class="questions" v-for="question in slide.questions" :key="question.id">
                                        <div class="question">
                                            <h4>
                                                <v-icon v-if="question.correctAnswer === question.givenAnswer" color="green">mdi-check</v-icon>
                                                <v-icon v-else-if="question.givenAnswer" color="red">mdi-alert-rhombus-outline</v-icon>
                                                <v-icon v-else>mdi-clock-outline</v-icon>
                                                <router-link v-if="question.isSubmitted" :to="{ name: 'question', params: { chapterId: slide.chapter.id, slideId: slide.id, questionId: question.id }}">
                                                    {{question.index}}. {{question.question}}
                                                </router-link>
                                                <span v-else>
                                                    {{question.index}}. {{question.question}}
                                                </span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ChapterFinishedMessage v-if="chapter.isFinished" v-bind:chapter="chapter" />
                        </v-card-text>

                        <v-card-actions>
                            <v-btn x-large text color="primary" v-on:click="navigateToChaptersOverview()">
                                <v-icon left>mdi-arrow-left-bold-circle</v-icon> Terug
                            </v-btn>

                            <v-spacer />

                            <v-btn v-if="chapter.isFinished && chapter.test.nextChapter && chapter.test.nextChapter.nextSlide" x-large text color="primary" :to="{ name: 'slide', params: { chapterId: chapter.test.nextChapter.id, slideId: chapter.test.nextChapter.nextSlide.id }}">
                                Volgende module <v-icon right>mdi-arrow-right-bold-circle</v-icon>
                            </v-btn>
                            <v-btn v-if="!chapter.test.finishedAt && !chapter.test.nextChapter" v-on:click="onFinishTrainingClick" color="primary" x-large text>
                                Training afronden <v-icon right>mdi-arrow-right-bold-circle</v-icon>
                            </v-btn>
                            <v-btn v-if="!chapter.isFinished && chapter.nextSlide" x-large text color="primary" :to="{ name: 'slide', params: { chapterId: chapter.id, slideId: chapter.nextSlide.id }}">
                                Verder <v-icon right>mdi-arrow-right-bold-circle</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import HeaderImage from './components/HeaderImage';
import ChapterFinishedMessage from './components/ChapterFinishedMessage';
import TestService from './services/TestService';
import store from './store';

export default {
    components: {
        HeaderImage,
        ChapterFinishedMessage,
    },
    computed: {
        chapter () {
            return store.getters.getChapterById(parseInt(this.$route.params.chapterId));
        }
    },
    methods: {
        navigateToChaptersOverview () {
            this.$router.push({ name: 'chapters' });
        },
        onFinishTrainingClick () {
            TestService.finishTest(this.chapter.test);
            this.$router.push({ name: 'chapters' });
        }
    }
};
</script>

<style scoped>
img.video-placeholder {
    width: 100%;
}
.slides {
    margin: 0 -16px;
}
.slides .slide h3 {
    background-color: #ddd;
    padding: 10px;
    text-decoration: none;
}
.slides .slide a {
    text-decoration: none;
}
.slides .slide a:hover {
    text-decoration: underline;
}
.slides .slide h4 {
    font-size: 16px;
    font-weight: normal;
    /* background-color: #ccc; */
    padding: 4px 10px;
}
.slide h4 i.v-icon {
    font-size: 20px;
    margin-top: -3px;
}
</style>
