<template>
    <v-main>
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4">
                    <v-card class="elevation-12">
                        <HeaderImage/>

                        <v-card-text class="px-2 mt--20">
                            <v-col align="start" justify="start">
                                <span>{{question.slide.name}}</span><br />
                                <h1>{{question.question}}</h1>

                                <p class="well assertion" v-if="question.isSubmitted && question.assertion">
                                    <span class="label" v-if="question.isSubmitted && question.givenAnswer === question.correctAnswer">Juist beantwoord</span>
                                    <span class="label" v-else-if="question.isSubmitted && question.givenAnswer !== question.correctAnswer">Onjuist beantwoord</span>
                                    {{question.assertion}}
                                </p>

                                <v-form>
                                    <v-radio-group v-model="question.givenAnswer" :class="question.isSubmitted ? 'is-submitted' : ''">
                                        <v-radio color="primary" v-for="answer in question.answers" :key="answer.id" :value="answer" :class="question.isSubmitted ? answer === question.correctAnswer ? 'is-correct' : 'is-wrong' : ''">
                                            <span slot="label">
                                                {{answer.answer}}
                                                <span v-if="question.isSubmitted && answer === question.correctAnswer" class="green--text">(Juiste antwoord)</span>
<!--                                                <span v-else-if="question.isSubmitted && answer === question.givenAnswer" class="red&#45;&#45;text">(Gegeven antwoord)</span>-->
                                            </span>
                                        </v-radio>
                                    </v-radio-group>
                                    <div class="form-overlay" v-if="question.isSubmitted"></div>
                                </v-form>
                            </v-col>
                        </v-card-text>

                        <QuestionProgressIndication v-bind:question="question"/>

                        <v-card-actions>
                            <v-btn v-if="chapterWasFinishedBeforeHand" x-large text color="primary" v-on:click="navigateToChapterOverview()">
                                <v-icon left>mdi-arrow-left-bold-circle</v-icon> Terug
                            </v-btn>

                            <v-spacer />

                            <v-btn v-if="!question.isSubmitted" x-large text color="primary" :disabled="!question.givenAnswer || isSubmittingAnswer" v-on:click="onSubmitClick()">
                                <v-icon left>mdi-checkbox-marked-circle-outline</v-icon> OK
                            </v-btn>
                            <v-btn v-else-if="question.next" x-large text color="primary" v-on:click="navigateToNextQuestion">
                                Volgende vraag <v-icon right>mdi-arrow-right-bold-circle</v-icon>
                            </v-btn>
                            <v-btn v-else-if="question.slide.next" x-large text color="primary" v-on:click="navigateToNextSlide">
                                Volgende slide <v-icon right>mdi-arrow-right-bold-circle</v-icon>
                            </v-btn>
                            <v-btn v-else x-large text color="primary" v-on:click="navigateToChapterOverview(true)">
                                Bekijk tussenscore <v-icon right>mdi-arrow-right-bold-circle</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import HeaderImage from './components/HeaderImage';
import QuestionProgressIndication from './components/QuestionProgressIndication';
import TestService from './services/TestService';

export default {
    components: {
        HeaderImage,
        QuestionProgressIndication,
    },

    data: () => ({
        chapterWasFinishedBeforeHand: false,
        isSubmittingAnswer: false,
    }),

    computed: {
        question () {
            return this.$store.getters.getQuestionById(parseInt(this.$route.params.questionId));
        },
    },

    mounted () {
        if (this.question.slide.chapter.isFinished) {
            this.chapterWasFinishedBeforeHand = true;
        }
    },

    methods: {
        onSubmitClick() {
            this.isSubmittingAnswer = true;
            TestService.giveAnswer(this.question.givenAnswer).then(() => {
                this.isSubmittingAnswer = false;
            })
        },

        navigateToNextQuestion () {
            let question = this.question;

            this.$router.push({
                name: 'question',
                params: {
                    chapterId: question.slide.chapter.id,
                    slideId: question.slide.id,
                    questionId: question.next.id,
                }
            });
        },

        navigateToNextSlide () {
            let question = this.question;

            // Redundancy
            TestService.finishSlide(question.slide);

            this.$router.push({
                name: 'slide',
                params: {
                    chapterId: question.slide.chapter.id,
                    slideId: question.slide.next.id,
                }
            });
        },

        navigateToChapterOverview (firstTime = false) {
            let question = this.question;

            if (firstTime) {
                // Redundancy
                TestService.finishSlide(question.slide);
            }

            this.$router.push({
                name: 'chapter',
                params: {
                    chapterId: question.slide.chapter.id
                }
            });
        }
    }
};
</script>

<style>
    h1 {
        font-size: 24px;
        line-height: 36px;
    }
    p.assertion {
        font-size: 16px;
    }
    p.assertion span.label {
        font-size: 16px;
        font-weight: bolder;
        display: block;
    }
    .v-input.is-submitted {
        pointer-events: none;
    }
    .v-radio.is-correct i.v-icon {
        color: #00bb18 !important;
    }
    .v-radio.is-correct label {
        font-weight: bold;
    }
    .v-radio.v-item--active.is-wrong i.v-icon {
        color: #ff0000 !important;
    }
    .v-radio.v-item--active.is-wrong label{
        /* font-weight: bold; */
    }

    form.v-form {
        position: relative;
    }

    .form-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }


    @media screen and (min-width: 720px) {
        .question-progress-indicator {
            margin-bottom: -44px;
        }
    }
</style>
