import store from '../store';
import AxiosApi from '@/services/AxiosApi';
import TestTransformer from '@/services/TestTransformer';

const TestService = {
    _testIsLoaded: false,

    async loadTest (forceReload = false) {
        if (!this._testIsLoaded || forceReload) {
            if (forceReload) {
                this.unloadTest();
            }

            return store.dispatch('jv/get', [`tests/@latest`, { params: {
                include: 'chapters,slides,questions,answers'
            }}]).then((data) => {
                this._testIsLoaded = true;

                store.dispatch('setTest', TestTransformer.transform(data));

                return new Promise((resolve) => {
                    resolve(data);
                });
            });
        } else {
            return new Promise((resolve) => {
                resolve(store.getters['jv/get'](`tests/${store.state.test}`));
            });
        }
    },

    isTestLoaded () {
        return this._testIsLoaded;
    },

    unloadTest () {
        this._testIsLoaded = false,
        store.dispatch('setTest', {
            chapters: [],
            slides: [],
            questions: [],
            answers: [],
        });
        // Todo unload test from JV store
    },

    async giveAnswer(answer) {
        let isSuccess = false;

        // Submit answer to API
        return AxiosApi.post('/api/submit-answer', {
            testId: answer.question.slide.chapter.test.id, answerId: answer.id
        }).then((result) => {
            if (result.data.success) {
                answer.question.givenAnswer = answer;
                answer.question.isSubmitted = true;
                answer.question.slide.chapter.test.finishedQuestions.push(answer.question);
                answer.question.slide.chapter.test.givenAnswers.push(answer);
                answer.question.slide.nextQuestion = answer.question.next;
                answer.question.slide.previousQuestion = answer.question;

                if (answer.question.correctAnswer === answer && !answer.question.isSample) {
                    answer.question.slide.chapter.test.correctAnswerCount++;
                }

                isSuccess = true;
            }

            // if (!isSuccess) {
            //     window.console.log('Fail submitting slide', result);
            // }

            return new Promise((resolve) => {
                resolve(isSuccess);
            });
        }).catch(() => {
            window.console.log('Fail submitting answer');

            return new Promise((resolve) => {
                resolve(isSuccess);
            });
        }).finally(() => {
            if (!answer.question.slide.nextQuestion) {
                this.finishSlide(answer.question.slide);
            }
        });
    },

    async finishSlide(slide) {
        let isSuccess = false;

        // Submit answer to API
        return AxiosApi.post('/api/finish-slide', {
            testId: slide.chapter.test.id, slideId: slide.id
        }).then((result) => {
            if (result.data.success) {
                slide.isFinished = true;
                slide.chapter.test.finishedSlides.push(slide);
                slide.chapter.nextSlide = slide.next;
                slide.chapter.previousSlide = slide;

                isSuccess = true;
            }

            // if (!isSuccess) {
            //     window.console.log('Fail submitting slide', result);
            // }

            return new Promise((resolve) => {
                resolve(isSuccess);
            });
        }).catch(() => {
            // window.console.log('Fail submitting slide');

            return new Promise((resolve) => {
                resolve(isSuccess);
            });
        }).finally(() => {
            if (!slide.chapter.nextSlide) {
                this.finishChapter(slide.chapter);
            }
        });
    },

    finishChapter (chapter) {
        chapter.isFinished = true;
        chapter.test.finishedChapters.push(chapter);
        chapter.test.nextChapter = chapter.next;
        chapter.test.previousChapter = chapter;

        // if (!chapter.test.nextChapter) {
        //     this.finishTest(chapter.test);
        // }
    },

    finishTest (test) {
        test.finishedAt = new Date();
    },

    resetTest (test) {
        return AxiosApi.post('/api/reset-test', { testId: test.id }).then(() => {
            this.loadTest(true);
        });
    }
}

export default TestService
