const TestTransformer = {
    _finishedChaptersIds: [],
    _finishedSlidesIds: [],
    _finishedQuestionsIds: [],
    _givenAnswersIds: [],
    _sampleQuestionsCount: 0,

    transform(jvTest) {
        this._sampleQuestionsCount = 0;
        this._finishedChaptersIds = this._getFinishedChapterIds(jvTest);
        this._finishedSlidesIds = this._getFinishedSlidesIds(jvTest);
        this._finishedQuestionsIds = this._getFinishedQuestionsIds(jvTest);
        this._givenAnswersIds = this._getGivenAnswersIds(jvTest);

        let test = {
            id: jvTest._jv.id,
            finishedAt: jvTest['finished-at'],
            jvTest: jvTest,
            nextChapter: null,
            previousChapter: null,
            correctAnswerCount: jvTest['correct-answers-count'],
            chapters: [],
            slides: [],
            questions: [],
            answers: [],
            finishedChapters: [],
            finishedSlides: [],
            finishedQuestions: [],
            givenAnswers: [],
        };

        test.chapters = this._transformChapters(jvTest, test);
        test.sampleQuestionsCount = this._sampleQuestionsCount;

        return test;
    },

    _transformChapters(jvTest, test) {
        let chapters = [],
            chapter,
            previousChapter,
            orderedJvChapters = Object
                .keys(jvTest.chapters)
                .map(i => jvTest.chapters[i])
                .sort((a, b) => (a.position > b.position) ? 1 : -1)
            ;

        orderedJvChapters.forEach((jvChapter, index) => {
            chapter = {
                id: jvChapter._jv.id,
                index: ++index,
                name: jvChapter.name,
                title: jvChapter.title,
                duration: jvChapter.duration,
                thumbSrc: jvChapter.thumb,
                isFinished: this._finishedChaptersIds.includes(jvChapter._jv.id),
                test: test,
                previous: previousChapter,
                next: null,
            };
            chapter.slides = this._transformSlides(jvChapter.slides, chapter, test);

            if (previousChapter) {
                previousChapter.next = chapter;
            }

            chapters.push(chapter);
            test.chapters.push(chapter);
            if (chapter.isFinished) {
                test.finishedChapters.push(chapter);
                test.previousChapter = chapter;
            } else if (!test.nextChapter) {
                test.nextChapter = chapter;
            }

            previousChapter = chapter;
        });

        return chapters;
    },

    _transformSlides(jvSlides, chapter, test) {
        let slides = [],
            slide,
            previousSlide,
            orderedJvSlides = Object
                .keys(jvSlides)
                .map(i => jvSlides[i])
                .sort((a, b) => (a.position > b.position) ? 1 : -1)
            ;

        orderedJvSlides.forEach((jvSlide, index) => {
            slide = {
                id: jvSlide._jv.id,
                index: ++index,
                name: jvSlide.name,
                videoType: jvSlide['video-type'],
                videoId: jvSlide['video-id'],
                videoUrl: jvSlide['video-url'],
                isFinished: this._finishedSlidesIds.includes(jvSlide._jv.id),
                chapter: chapter,
                previous: previousSlide,
                next: null,
            };
            slide.questions = this._transformQuestions(jvSlide.questions, slide, test);

            if (previousSlide) {
                previousSlide.next = slide;
            }

            slides.push(slide);
            test.slides.push(slide);

            if (slide.isFinished) {
                test.finishedSlides.push(slide);
                chapter.previousSlide = slide;
            } else if (!chapter.nextSlide) {
                chapter.nextSlide = slide;
            }

            previousSlide = slide;
        });

        return slides;
    },

    _transformQuestions(jvQuestions, slide, test) {
        let questions = [],
            question,
            previousQuestion,
            orderedJvQuestions = Object
                .keys(jvQuestions)
                .map(i => jvQuestions[i])
                .sort((a, b) => (a.position > b.position) ? 1 : -1)
            ;

        orderedJvQuestions.forEach((jvQuestion, index) => {
            question = {
                id: jvQuestion._jv.id,
                index: ++index,
                question: jvQuestion.question,
                assertion: jvQuestion.assertion,
                shuffleAnswers: jvQuestion['shuffle-answers'],
                isSample: jvQuestion['is-sample'],
                slide: slide,
                previous: previousQuestion,
                next: null,
                givenAnswer: null,
                isSubmitted: false,
            };
            question.answers = this._transformAnswers(jvQuestion.answers, question, test);

            if (question.isSample) {
                this._sampleQuestionsCount++;
            }

            question.correctAnswer = question.answers.filter((answer) => {
                return answer.id === jvQuestion['correct-answer']._jv.id;
            })[0];

            question.givenAnswer = question.answers.find((answer) => {
                return this._givenAnswersIds.find((answerId) => {
                    return answerId === answer.id;
                });
            });

            if (question.givenAnswer) {
                question.isSubmitted = true;
                test.givenAnswers.push(question.givenAnswer);
            }

            if (previousQuestion) {
                previousQuestion.next = question;
            }

            questions.push(question);
            test.questions.push(question);

            if (questions.isSubmitted) {
                test.finishedQuestions.push(question);
                slide.previousQuestion = question;
            } else if (!slide.nextQuestion) {
                slide.nextQuestion = question;
            }

            previousQuestion = question;
        });

        return questions;
    },

    _transformAnswers(jvAnswers, question, test) {
        let answers = [],
            answer,
            orderedJvAnswers;

        if (question.shuffleAnswers) {
            orderedJvAnswers = this._shuffleArray(Object
                .keys(jvAnswers)
                .map(i => jvAnswers[i])
            );
        } else {
            orderedJvAnswers = Object
                .keys(jvAnswers)
                .map(i => jvAnswers[i])
                .sort((a, b) => (a.position > b.position) ? 1 : -1)
            ;
        }

        orderedJvAnswers.forEach((jvAnswer, index) => {
            answer = {
                id: jvAnswer._jv.id,
                index: ++index,
                answer: jvAnswer.answer,
                question: question,
            };

            answers.push(answer);
            test.answers.push(answer);
        });

        return answers;
    },

    _getFinishedChapterIds(test) {
        return Object.keys(test['finished-chapters']).map(i => test['finished-chapters'][i]._jv.id);
    },

    _getFinishedSlidesIds(test) {
        return Object.keys(test['finished-slides']).map(i => test['finished-slides'][i]._jv.id);
    },

    _getFinishedQuestionsIds(test) {
        return Object.keys(test['finished-questions']).map(i => test['finished-questions'][i]._jv.id);
    },

    _getGivenAnswersIds(test) {
        return Object.keys(test['given-answers']).map(i => test['given-answers'][i]._jv.id);
    },

    /**
     * Shuffles array in place.
     * @param {Array} a items An array containing the items.
     */
    _shuffleArray(a) {
        let j, x, i;

        for (i = a.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = a[i];
            a[i] = a[j];
            a[j] = x;
        }

        return a;
    },
};

export default TestTransformer
