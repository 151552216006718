<template>
    <div class="question-progress-indicator text-center text--disabled text--darken-2">
        vraag {{questionIndex}} van {{questionsCount}}
    </div>
</template>

<script>
export default {
    name: 'QuestionProgressIndication',

    props: {
        question: Object,
    },

    computed: {
        questionsCount() {
            return this.question.slide.questions.length;
        },
        questionIndex() {
            return this.question.slide.questions.indexOf(this.question) + 1;
        }
    }
};
</script>

<style scoped>
    div {
        font-size: 15px;
    }
</style>