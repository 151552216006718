import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import store from './store';
import AuthenticationManager from './services/AuthenticationManager';
import TrainingVariantContext from './services/TrainingVariantContext';
import TestService from './services/TestService';
import UserService from './services/UserService';
import vueVimeoPlayer from 'vue-vimeo-player';
import VueGtag from 'vue-gtag';
// import Hotjar from 'vue-hotjar';

// Routes
import Chapters from './Chapters'
import Chapter from './Chapter'
import Slide from './Slide'
import Question from './Question'
import Registration from './Registration'
import Login from './Login'
import Explanation from './Explanation'
import PrivacyPolicy from './PrivacyPolicy'
import NotFound404 from './NotFound404'

Vue.config.productionTip = false;

Vue.use(VueRouter);

const routes = [
    { name: 'chapters', path: '/', component: Chapters },
    { name: 'slide', path: '/:chapterId/slides/:slideId', component: Slide },
    { name: 'question', path: '/:chapterId/slides/:slideId/questions/:questionId', component: Question },
    { name: 'registration', path: '/registreren', component: Registration, meta: { unauthenticated: true, authenticated: false } },
    { name: 'login', path: '/login', component: Login, meta: { unauthenticated: true, authenticated: false } },
    { name: 'privacyPolicy', path: '/privacy-policy', component: PrivacyPolicy, meta: { authenticated: false } },
    { name: 'explanation', path: '/uitleg', component: Explanation },
    { name: 'chapter', path: '/:chapterId(\\d+)', component: Chapter },
    { name: 'notFound404', path: '*', component: NotFound404 },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    const isAuthenticatedRoute = !to.meta.hasOwnProperty('authenticated') ? true : to.meta.authenticated;
    const isUnauthenticatedRoute = !to.meta.hasOwnProperty('unauthenticated') ? false : to.meta.unauthenticated;

    if (window.location.search.includes('token')) {
        AuthenticationManager.authenticateByToken().then((isAuthenticated) => {
            window.history.replaceState({}, document.title, window.location.origin + window.location.pathname);

            if (isAuthenticated) {
                return next({ name: 'explanation' });
            } else {
                return next({ name: 'registration' });
            }
        });
    } else {
        AuthenticationManager.isAuthenticated().then((isAuthenticated) => {
            if (isAuthenticated) {
                if (isUnauthenticatedRoute) {
                    // Go to authentication route index
                    return next({ name: 'chapters' });
                }

                if (!UserService.userIsLoaded()) {
                    // Load user
                    UserService.fetchLoggedInUser();
                }

                if (!TestService.isTestLoaded()) {
                    TestService.loadTest().then(() => {
                        next();
                    });
                } else {
                    next();
                }
            } else {
                // Is not authenticated
                if (isAuthenticatedRoute) {
                    // Go to training portal
                    return next({ name: 'registration' });
                } else {
                    next();
                }
            }
        });
    }
});

router.afterEach((to) => {
    if ('helprAPI' in window && 'initialized' in window.helprAPI && window.helprAPI.initialized) {
        switch (to.name) {
            case 'slide':
                window.helprAPI.pageMessage({
                    'index': store.getters.getSlideIndexById(to.params.slideId),
                    'training': TrainingVariantContext.getHelprTrainingLabel(),
                });
                break;
            default:
                window.helprAPI.pageMessage({
                    'index': to.name,
                    'training': TrainingVariantContext.getHelprTrainingLabel(),
                });
        }
    }
});

Vue.use(VueGtag, {
    config: {
        id: "G-0WZD4MEVTD",
        params: {
            anonymize_ip: true,
            anonymizeIp: true,
        },
    }
}, router);

// Vue.use (Hotjar, {
//     id: '2130260',
//     isProduction: true,
// });

Vue.use(vueVimeoPlayer);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
