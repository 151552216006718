<template>
    <div class="admin-toolbar hidden-sm-and-down">
        <v-app-bar>
            <v-toolbar-title>Admin toolbar</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn :to="{ name: 'registration' }" active-class="orange" exact>Register</v-btn>
            <v-btn :to="{ name: 'login' }" active-class="orange" class="ml-2" exact>Login</v-btn>
            <v-btn :to="{ name: 'explanation' }" active-class="orange" class="ml-2" exact>Explanation</v-btn>
            <v-btn :to="{ name: 'chapters' }" active-class="orange" class="ml-2" exact>Modules</v-btn>

            <template v-if="test">
                <template v-for="chapter in test.chapters">
                    <v-menu :key="chapter.id" left bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="ml-2">
                                {{chapter.name}}
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item :to="{ name: 'chapter', params: { chapterId: chapter.id }}">
                                <v-list-item-title>{{chapter.name}} overview</v-list-item-title>
                            </v-list-item>

                            <template v-for="slide in chapter.slides">
                                <v-list-item :key="slide.id" :to="{ name: 'slide', params: { chapterId: chapter.id, slideId: slide.id }}">
                                    <v-list-item-title>Slide: {{slide.name}}</v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-menu>
                </template>
            </template>

            <v-spacer></v-spacer>

            <v-checkbox v-model="localPreventRedirect" label="Prevent Helpr redirects" hide-details></v-checkbox>
        </v-app-bar>
    </div>
</template>

<script>
export default {
    name: 'AdminNavigation',

    watch: {
        localPreventRedirect() {
            this.$emit('setPreventRedirect', this.localPreventRedirect);
        }
    },

    data: () => ({
        localPreventRedirect: false,
    }),

    created() {
        this.localPreventRedirect = this.preventRedirect;
    },

    computed: {
        test() {
            return this.$store.state.test;
        },
    },

    props: {
        preventRedirect: Boolean,
    }
};
</script>

<style>
    @media only screen and (min-width: 960px) {
        .admin-toolbar + .v-app-bar {
            margin-top: 60px !important;
        }
    }
</style>