<template>
    <div class="text-center">
        <img class="header-image" src="/images/nvwa-logo-header-fw.png" />
    </div>
</template>

<script>
export default {
    name: 'HeaderImage',
};
</script>

<style scoped>
    img.header-image {
        width: 100%;
        max-width: 500px;
    }
</style>