import axios from 'axios'

const AxiosApi = axios.create({
    // baseURL: '//api.prodrive-elearning.defineweb.test',
    baseURL: '//api.prodrive-elearning.defineweb.nl',
    // baseURL: '//api.onlinetraining.prodriveacademy.nl',
    headers: {
        'Content-Type': 'application/vnd.api+json',
    },
});

export default AxiosApi
