<template>
    <v-main>
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="10" md="6" xl="6">
                    <v-card class="elevation-12">
                        <HeaderImage/>
                        <v-card-text class="px-12">
                            <v-row align="center" justify="start" class="mt-2">
                                <h1>Welkom!</h1>
                            </v-row>
                            <v-row align="center" justify="start" class="mt-2">
                                <p class="elektrisch-rijden">
                                    Welkom bij de training ’Elektrisch rijden’ van NVWA. De training helpt je meer inzicht te krijgen in het gebruik en de voordelen van elektrisch rijden. Je krijgt de vragen van Max, de Learning Bot. Deze vragen gaan over de video's en helpt je op deze manier te reflecteren en de informatie te verwerken.
                                </p>
                                <p class="abcde">
                                    Welkom bij de training ‘top 3 schadeoorzaken’ van NVWA. De training biedt tips en tricks die je kunnen helpen bij schade voorkomen. Het geeft inzicht in risico situaties en biedt handvatten om hierop te anticiperen. Je krijgt de vragen van Max, de Learning Bot. Deze vragen gaan over de video's en helpt je op deze manier te reflecteren en de informatie te verwerken.
                                </p>
                            </v-row>

                            <v-row align="center" justify="start" class="mt-4">
                                <p class="icon-left pr-20">
                                    <v-icon>mdi-volume-high</v-icon>
                                    Zet je geluid aan! Kan dat niet via de luidsprekers? Gebruik dan een hoofdtelefoon. In de online training komen veel video’s voor waarin informatie wordt verteld.
                                </p>
                            </v-row>
                            <v-row align="center" justify="start" class="mt-4">
                                <p class="icon-left pr-20">
                                    <v-icon>mdi-clock-outline</v-icon>
                                    <span class="elektrisch-rijden">De totale duur van de online training is ongeveer 30 minuten.</span>
                                    <span class="abcde">De totale duur van de online training is ongeveer 20 minuten.</span>
                                </p>
                            </v-row>
                            <v-row align="center" justify="start" class="mt-4">
                                <p class="icon-left pr-20">
                                    <v-icon>mdi-play-box</v-icon>
                                    Je kan de video's pauzeren en opnieuw afspelen als je denkt dat je iets belangrijks hebt gemist.
                                </p>
                            </v-row>
                            <v-row align="center" justify="start" class="mt-4">
                                <p>
                                    Heb je vragen of opmerkingen n.a.v. deze training? Stuur ze dan naar <a href="mailto:customerservice@vvcr-prodrive.nl">customerservice@vvcr-prodrive.nl</a>.
                                </p>
                            </v-row>
                        </v-card-text>

                        <v-card-actions class="mt-4" v-if="firstSlide">
                            <v-btn x-large text block color="primary" :to="{ name: 'slide', params: { chapterId: firstSlide.chapter.id, slideId: firstSlide.id }}">
                                Verder <v-icon right>mdi-arrow-right-bold-circle</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import HeaderImage from './components/HeaderImage';

export default {
    components: {
        HeaderImage,
    },

    computed: {
        firstSlide() {
            let test = this.$store.state.test;

            if (test && 1 <= test.slides.length) {
                return test.slides[0];
            }

            return false;
        },
    },
};
</script>
