<template>
    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
        <v-hover v-slot:default="{ hover }">
            <v-card :to="!chapter.isFinished && chapter.nextSlide ? { name: 'slide', params: { chapterId: chapter.id, slideId: chapter.nextSlide.id } } : { name: 'chapter', params: { chapterId: chapter.id } }" :elevation="hover ? 8 : 2" :class="`chapter ${chapter.isFinished ? 'finished ' : ''}${test.nextChapter === chapter ? 'is-next ' : ''}${!chapter.isFinished && test.nextChapter ===  chapter ? 'queued ' : ''}mx-auto chapter-card`" :disabled="!chapter.isFinished && test.nextChapter !== chapter">
                <v-img :src="chapter.thumbSrc" class="module-thumb">
                    <span v-if="chapter.duration" class="duration-indication"><v-icon>mdi-alarm</v-icon> {{chapter.duration}} min</span>
                </v-img>

                <v-card-title>
                    <span v-if="chapter.title">{{ chapter.title }}</span>
                    <span v-else>Module {{ chapter.index }}</span>

                    <v-icon right v-if="chapter.isFinished" class="green--text text--darken-2">mdi-check-circle</v-icon>
                    <v-icon right v-else-if="test.nextChapter === chapter" class="blue-grey--text text--darken-1">mdi-play-circle</v-icon>
                    <v-icon right v-else class="text--lighten-4">mdi-clock</v-icon>
                </v-card-title>

                <v-card-subtitle>{{ chapter.name }}</v-card-subtitle>

<!--                <v-card-text>-->
<!--                    <ul class="slides">-->
<!--                        <li v-for="slide in chapter.slides" :key="slide.id" :class="slide.isFinished ? 'finished' : ''">{{slide.name}}</li>-->
<!--                    </ul>-->
<!--                </v-card-text>-->
            </v-card>
        </v-hover>
    </v-col>
</template>

<script>
export default {
    name: 'ChapterCard',

    props: {
        chapter: {
            type: Object,
            required: true,
        },
        test: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>
    .chapter-card {
        -moz-transition: 0.1s;
        -ms-transition: 0.1s;
        -o-transition: 0.1s;
        -webkit-transition: 0.1s;
        transition: 0.1s;
        min-height: 0;
    }

    ul.slides li {
        list-style-type: none;
    }
    ul.slides li::before {
        content: "\F0E1E";
        font-family: "Material Design Icons";
        font-size: 16px;
        position: absolute;
        left: 18px;
        display: inline-block;
        text-rendering: auto;
        line-height: inherit;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-decoration: inherit;
        vertical-align: inherit;
    }
    ul.slides li.finished::before {
        color: green;
    }
</style>